import React from 'react';
import profileStyle from '../components/profile.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

class Profile extends React.Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div
        data-aos="fade-up"
        data-aos-delay={this.props.animeOrder * 100}
        className={profileStyle.container}
        style={{ backgroundImage: `url(${this.props.picture})`, flex: `${this.props.flex}` }}
      >
        <div className={profileStyle.overlay}>
          <div className={profileStyle.name}>{this.props.name}</div>
          <div className={profileStyle.position}>{this.props.position}</div>
          <div className={profileStyle.contact}>
            <div className={profileStyle.mail}>
              {this.props.mail !== undefined && (
                <a href={'mailto:' + this.props.mail + '@mds-digitalagency.be'}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              )}
            </div>
            <div className={profileStyle.phone}>
              {this.props.phone !== undefined && (
                <a href={'tel:' + this.props.phone}>
                  <FontAwesomeIcon icon={faPhone} />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
