import React from 'react';
import aboutCardStyle from '../components/about.card.module.css';

class AboutCard extends React.Component {
  render() {
    return (
      <div className={aboutCardStyle.card} data-aos="zoom-in">
        <img src={this.props.img} alt={this.props.name} />
        <h2>{this.props.name}</h2>
      </div>
    );
  }
}

export default AboutCard;
