import React from 'react';
import profileStyle from '../components/profile.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

class JobApply extends React.Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div
        data-aos="fade-up"
        className={profileStyle.container_join}
        style={{ background: '#e6e6e6' }}
      >
        <div className={profileStyle.join}>
          <h2>Envie de nous rejoindre ?</h2>
          <p>C'est par ici</p>
        </div>
      </div>
    );
  }
}

export default JobApply;
